import React, { useState } from 'react';
import * as S from './styles';
import { Topbar, Navbar, DashboardFooter, Breadcrumb } from '../../components';
import { usePermissions, useStorage } from '../../hooks';
import { profileFields } from '../../utils/mocks/profile';
import refresh from '../../assets/dashboard-icons/refresh.png';
import { sendNotification } from '../../utils/functions';

export const Profile: React.FC = () => {
  const { getItemOnStorage, setItemOnStorage } = useStorage();
  const [{ isLoading, error }, , , , , , updateUserCode] = usePermissions();

  const [user, setUser] = useState(
    JSON.parse(getItemOnStorage('currentUser') || '{"data":"empty"}'),
  );

  const vendorData = getItemOnStorage('vendorData') || '{"data":"empty"}';
  const data = JSON.parse(vendorData).data;

  const fieldsToRender = profileFields.filter(
    profile => profile.name === user.perfil,
  )[0].fields;

  const handleUpdateUserCode = async () => {
    try {
      const response = await updateUserCode(user.userEmail);
      setUser((prevUser: any) => ({
        ...prevUser,
        vendedor: response?.sellerCode,
      }));
      const updatedCurrentUser = {
        ...user,
        vendedor: response?.sellerCode,
      };
      setItemOnStorage('currentUser', JSON.stringify(updatedCurrentUser));
      sendNotification('Código de vendedor atualizado com sucesso!', {
        type: 'success',
      });
    } catch (error: any) {
      sendNotification(
        'Erro ao atualizar código de vendedor: ' + error.message,
        { type: 'error' },
      );
    }
  };

  return (
    <S.Container>
      <Topbar />
      <Navbar />
      <S.Content>
        <Breadcrumb paths={paths} />
        <S.Title>Meu Perfil</S.Title>
        <S.ContentContainer>
          {fieldsToRender.includes('email') && (
            <S.Info>
              <S.Text className="bold">E-mail:</S.Text>
              <S.Text className="extended">{user.userEmail ?? '-'}</S.Text>
            </S.Info>
          )}
          {fieldsToRender.includes('nome') && (
            <S.Info>
              <S.Text className="bold">Nome:</S.Text>
              <S.Text className="extended">{user.user ?? '-'}</S.Text>
            </S.Info>
          )}
          {fieldsToRender.includes('cpfCnpj') && (
            <S.Info>
              <S.Text className="bold">CNPJ/CPF:</S.Text>
              <S.Text className="extended">
                {(user.cnpj &&
                  user.cnpj.replace(
                    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    '$1.$2.$3/$4-$5',
                  )) ??
                  '-'}
              </S.Text>
            </S.Info>
          )}
          {fieldsToRender.includes('grupoEconomico') && (
            <S.Info>
              <S.Text className="bold">Grupo Econômico:</S.Text>
              <S.Text className="extended">
                {user.gruposEconomicos[0] ?? '-'}
              </S.Text>
            </S.Info>
          )}
          {fieldsToRender.includes('vendedor') && (
            <S.Info>
              <S.Text className="bold">Código de Vendedor:</S.Text>
              <S.Text className="vendor-code">{user.vendedor ?? '-'}</S.Text>
              <S.Buttons.Buttons>
                <S.Buttons.IconButton
                  disabled={isLoading}
                  className="edit refresh"
                  onClick={handleUpdateUserCode}
                >
                  <S.Icon
                    src={refresh}
                    alt="Atualizar código de vendedor"
                  ></S.Icon>
                  {isLoading ? 'Atualizando...' : 'Atualizar'}
                </S.Buttons.IconButton>
              </S.Buttons.Buttons>
            </S.Info>
          )}
          {fieldsToRender.includes('areaDeAtuacao') && (
            <S.Info>
              <S.Text className="bold"> Área de Atuação:</S.Text>
              <S.Text className="extended">{user.areaAtuacao ?? '-'}</S.Text>
            </S.Info>
          )}

          {fieldsToRender.includes('razaoSocial') && (
            <S.Info>
              <S.Text className="bold">Nome / Razão Social:</S.Text>
              <S.Text className="extended">{data.corporateName ?? '-'}</S.Text>
            </S.Info>
          )}
          {fieldsToRender.includes('nomeFantasia') && (
            <S.Info>
              <S.Text className="bold">Nome Fantasia:</S.Text>
              <S.Text className="extended">{data.tradeName ?? '-'}</S.Text>
            </S.Info>
          )}
          {fieldsToRender.includes('inscricaoEstadual') && (
            <S.Info>
              <S.Text className="bold">Inscrição Estadual:</S.Text>
              <S.Text className="extended">
                {data.inscricaoEstadual ?? '-'}
              </S.Text>
            </S.Info>
          )}
          {fieldsToRender.includes('vendedorAdicional') && (
            <S.Info>
              <S.Text className="bold">Cód. de Vendedor Adicional:</S.Text>
              <S.Text className="extended">
                {data.vendedorAdicional ?? '-'}
              </S.Text>
            </S.Info>
          )}
          {fieldsToRender.includes('telefone') && (
            <S.Info>
              <S.Text className="bold">Telefone:</S.Text>
              <S.Text className="extended">
                {(data.homePhone &&
                  data.homePhone.replace(
                    /^([0-9]{2})([0-9]{4})([0-9]{4})$/,
                    '($1) $2-$3',
                    '',
                  )) ??
                  '-'}
              </S.Text>
            </S.Info>
          )}
          {fieldsToRender.includes('endereco') && (
            <S.Info>
              <S.Text className="bold">Endereço:</S.Text>
              <S.Text className="extended">
                {data.street ??
                  '' + ' - ' + data.neighborhood ??
                  '' + ' - ' + data.city ??
                  '' + ' - ' + data.state ??
                  '' + ' - ' + data.postalCode ??
                  ''}
              </S.Text>
            </S.Info>
          )}
          {fieldsToRender.includes('dadosBancarios') && (
            <S.Info>
              <S.Text className="bold">Dados Bancários:</S.Text>
              {data.agencia && (
                <S.Text className="extended">
                  {data.banco +
                    ' - Ag. ' +
                    data.agencia +
                    ' - CC.: ' +
                    data.contaCorrenteDigito}
                </S.Text>
              )}
            </S.Info>
          )}
        </S.ContentContainer>
      </S.Content>
      <DashboardFooter />
    </S.Container>
  );
};

const paths = [
  {
    title: 'Meu Painel',
    link: '/painel',
  },
  {
    title: 'Meu Perfil',
    link: '/perfil',
  },
];
