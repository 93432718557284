import React, { useEffect, useState, useCallback } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import * as XLSX from 'xlsx';
import * as S from './styles';
import { OrderCardBoleto } from '../../components/order-card-boleto';
import { useBoletos } from '../../hooks';
import {
  Topbar,
  Navbar,
  DashboardFooter,
  Breadcrumb,
  Loading,
  Pagination,
} from '../../components';
import { DynamicFilter } from '../../components/dynamic-filter';
import { IDynamicFilterData } from '../../interfaces';
import bannerDesktop from '../../assets/banner_site_boletos.png';
import bannerMobile from '../../assets/banner_mobile_boletos.png';
import { formatCNPJ, formatCPF } from '../../utils/functions';

export const Boletos: React.FC = () => {
  const {
    isLoading,
    response,
    responseResumed,
    qtdeRegistros,
    errors,
    responseExcel,
    returnTitles,
    returnTitlesResumed,
  } = useBoletos();

  const today = new Date();
  const startDate = new Date();
  startDate.setDate(today.getDate() - 45);
  const endDate = new Date();
  endDate.setDate(today.getDate() + 45);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const [activeTab, setActiveTab] = useState<string>('open');

  const [dateStart, setDateStart] = useState<string>(
    startDate.toISOString().split('T')[0],
  );
  const [dateEnd, setDateEnd] = useState<string>(
    today.toISOString().split('T')[0],
  );

  const [paginatedResumed, setPaginatedResumed] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [itensPorPagina] = useState(20);
  const indiceUltimoItem = paginaAtual * itensPorPagina;
  const indicePrimeiroItem = indiceUltimoItem - itensPorPagina;
  const itensAtuais = responseResumed.slice(
    indicePrimeiroItem,
    indiceUltimoItem,
  );
  const numeroPaginas = [];
  for (let i = 1; i <= Math.ceil(responseResumed.length / 20); i++) {
    numeroPaginas.push(i);
  }

  const trocaPagina = (numeroPagina: any) => {
    window.scrollTo(0, 0);
    setPaginatedResumed(itensAtuais);
    setPaginaAtual(numeroPagina);
  };

  const [pagination, setPagination] = useState({
    current: 0,
    size: 20,
  });

  const totalPages = Math.ceil(qtdeRegistros / pagination.size);

  useEffect(() => {
    filterTitles('open');
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setBannerSrc(bannerMobile);
      } else {
        setBannerSrc(bannerDesktop);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const exportToExcel = (data: any) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Boletos');

    const excelBuffer = XLSX.write(workbook, {
      type: 'array',
      bookType: 'xlsx',
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = 'boletos.xlsx';
    downloadLink.click();
  };

  const handleExport = () => {
    exportToExcel(responseExcel);
  };

  const filterSelectOptions = [
    { value: 'razaoSocial', description: 'Nome/Razão social' },
    { value: 'cpfCnpj', description: 'CNPJ/CPF' },
    { value: 'notaFiscal', description: 'Nota Fiscal' },
  ];

  const [filters, setFilters] = useState<IDynamicFilterData>({
    type: filterSelectOptions[0].value,
    term: '',
    dateStart: startDate.toISOString().split('T')[0],
    dateEnd: endDate.toISOString().split('T')[0],
  });

  const [bannerSrc, setBannerSrc] = useState(bannerDesktop);

  const searchOrders = async (filterData: IDynamicFilterData) => {
    setPagination({
      current: 0,
      size: 20,
    });

    returnTitles({
      ...filterData,
      filter: activeTab,
      currentPage: pagination.current,
      pageSize: pagination.size,
    });
  };

  const paginationSearch = (index: number) => {
    if (index >= 0 && index <= totalPages) {
      window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });

      setPagination({
        current: index,
        size: pagination.size,
      });
      returnTitles({
        ...filters,
        filter: activeTab,
        currentPage: index,
        pageSize: pagination.size,
      });
    }
  };

  const filterTitles = useCallback(
    async (filter: string) => {
      setActiveTab('open');
      setPagination({
        current: 0,
        size: 20,
      });

      switch (filter) {
        case 'open': {
          setActiveTab('open');

          const newFilters: IDynamicFilterData = {
            ...filters,
            dateStart: today.toISOString().split('T')[0],
            dateEnd: endDate.toISOString().split('T')[0],
          };
          setFilters(newFilters);

          await returnTitles({
            ...newFilters,
            filter: activeTab,
            currentPage: 0,
            pageSize: pagination.size,
          });

          break;
        }
        case 'closed': {
          setActiveTab('closed');

          const newFilters: IDynamicFilterData = {
            ...filters,
            dateStart: startDate.toISOString().split('T')[0],
            dateEnd: yesterday.toISOString().split('T')[0],
          };
          setFilters(newFilters);

          await returnTitles({
            ...newFilters,
            currentPage: 0,
            pageSize: pagination.size,
          });

          break;
        }
        default: {
          setActiveTab('default');

          const newFilters: IDynamicFilterData = {
            ...filters,
            dateStart: startDate.toISOString().split('T')[0],
            dateEnd: endDate.toISOString().split('T')[0],
          };
          setFilters(newFilters);

          await returnTitlesResumed({
            ...newFilters,
            currentPage: 0,
            pageSize: pagination.size,
          });

          break;
        }
      }
    },
    [response, filters],
  );

  const handleClearSearch = () => {
    const newFilters: IDynamicFilterData = {
      ...filters,
      term: '',
    };
    setFilters(newFilters);
    searchOrders(newFilters);
  };

  function dataTreatment(filtersToTreat: IDynamicFilterData) {
    if (filtersToTreat.type === 'cpfCnpj') {
      const numericValue = filtersToTreat.term.replace(/\D/g, '');

      const formattedValue =
        numericValue.length <= 11
          ? formatCPF(numericValue)
          : formatCNPJ(numericValue);

      const treatedFilters: IDynamicFilterData = {
        ...filtersToTreat,
        term: formattedValue,
      };

      return treatedFilters;
    }
    return filtersToTreat;
  }

  return (
    <S.Container>
      <Topbar />
      <Navbar />
      <img src={bannerSrc} width="100%" height="auto" alt="Banner" />
      <S.Content>
        <Breadcrumb paths={paths} />
        <S.Title>Portal de Boletos</S.Title>
        <DynamicFilter
          calendar
          filters={filters}
          isLoading={isLoading}
          setFilters={setFilters}
          handleExport={handleExport}
          searchFilters={searchOrders}
          dataTreatment={dataTreatment}
          filterSelectOptions={filterSelectOptions}
          handleClearSearch={handleClearSearch}
        />
        <S.Tabs.TabsNavigation>
          <S.Tabs.TabsSelector>
            <S.Tabs.TabSelector
              className={
                activeTab === 'open'
                  ? 'active'
                  : '' + (isLoading && ' loading-tab')
              }
              onClick={() => filterTitles('open')}
            >
              Títulos abertos
            </S.Tabs.TabSelector>
            <S.Tabs.TabSelector
              className={
                activeTab === 'closed'
                  ? 'active'
                  : '' + (isLoading && ' loading-tab')
              }
              onClick={() => filterTitles('closed')}
            >
              Títulos vencidos
            </S.Tabs.TabSelector>
            <S.Tabs.TabSelector
              className={
                activeTab === 'default'
                  ? 'active'
                  : '' + (isLoading && ' loading-tab')
              }
              onClick={() => filterTitles('')}
            >
              Resumo dos títulos
            </S.Tabs.TabSelector>
          </S.Tabs.TabsSelector>

          {activeTab !== 'default' ? (
            <S.Tabs.TabsContent>
              <thead>
                {/* TABLE HEAD */}
                <S.Tabs.TabsRow>
                  <S.Tabs.TabsHead>Razão social</S.Tabs.TabsHead>
                  <S.Tabs.TabsHead>Vencimento</S.Tabs.TabsHead>
                  <S.Tabs.TabsHead>Nota Fiscal</S.Tabs.TabsHead>
                  <S.Tabs.TabsHead>Parcela</S.Tabs.TabsHead>
                  <S.Tabs.TabsHead>Valor</S.Tabs.TabsHead>
                  <S.Tabs.TabsHead>CNPJ/CPF</S.Tabs.TabsHead>
                  <S.Tabs.TabsHead> </S.Tabs.TabsHead>
                </S.Tabs.TabsRow>
              </thead>
              <tbody>
                {/* TABLE DATA */}
                {!isLoading && (
                  <>
                    {response &&
                      response?.map((elm: any, idx: number) => {
                        return <OrderCardBoleto boleto={elm} key={idx} />;
                      })}
                  </>
                )}
              </tbody>
            </S.Tabs.TabsContent>
          ) : (
            <S.Tabs.TabsContent>
              <thead>
                {/* TABLE HEAD */}
                <S.Tabs.TabsRow>
                  <S.Tabs.TabsHead>Razão social</S.Tabs.TabsHead>
                  <S.Tabs.TabsHead>Total de títulos a vencer</S.Tabs.TabsHead>
                  <S.Tabs.TabsHead>Total de títulos vencidos</S.Tabs.TabsHead>
                  <S.Tabs.TabsHead>Total de títulos</S.Tabs.TabsHead>
                  <S.Tabs.TabsHead>CNPJ/CPF</S.Tabs.TabsHead>
                </S.Tabs.TabsRow>
              </thead>
              <tbody>
                {/* TABLE DATA */}
                {!isLoading && (
                  <>
                    {itensAtuais &&
                      itensAtuais?.map((elm: any, idx: number) => {
                        return (
                          <OrderCardBoleto
                            boleto={elm}
                            key={idx}
                            isDefault={true}
                          />
                        );
                      })}
                  </>
                )}
              </tbody>
            </S.Tabs.TabsContent>
          )}
        </S.Tabs.TabsNavigation>

        {!isLoading &&
          !errors &&
          qtdeRegistros !== 0 &&
          activeTab !== 'default' && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                padding: '24px 0',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Pagination
                startWithZero={true}
                currentPage={pagination.current}
                paginationSize={pagination.size}
                paginationSearch={paginationSearch}
                totalPages={totalPages}
              />
            </div>
          )}

        {!isLoading &&
          !errors &&
          numeroPaginas.length !== 0 &&
          activeTab === 'default' && (
            <S.Pagination>
              <p>Itens por página: 20</p>
              <p>
                {paginaAtual} de {numeroPaginas.length}
              </p>
              <S.PaginationArrow
                onClick={() => trocaPagina(1)}
                className={paginaAtual === 1 ? `disabled` : ``}
                disabled={paginaAtual === 1}
              >
                {'|<'}
              </S.PaginationArrow>
              <S.PaginationArrow
                onClick={() => trocaPagina(paginaAtual - 1)}
                className={paginaAtual === 1 ? `disabled` : ``}
                disabled={paginaAtual === 1}
              >
                {'<'}
              </S.PaginationArrow>
              <S.PaginationArrow
                onClick={() => trocaPagina(paginaAtual + 1)}
                className={
                  paginaAtual === numeroPaginas.length ? `disabled` : ``
                }
                disabled={paginaAtual === numeroPaginas.length}
              >
                {'>'}
              </S.PaginationArrow>
              <S.PaginationArrow
                onClick={() => trocaPagina(numeroPaginas.length)}
                className={
                  paginaAtual === numeroPaginas.length ? `disabled` : ``
                }
                disabled={paginaAtual === numeroPaginas.length}
              >
                {'>|'}
              </S.PaginationArrow>
            </S.Pagination>
          )}

        {!isLoading && !errors && qtdeRegistros === 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              padding: '24px 0 62px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <S.Items>
              {!isLoading && !errors && <p>Sem boletos listados.</p>}
            </S.Items>
          </div>
        )}

        {!isLoading && response.length === 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              padding: '24px 0 62px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <S.Items>{!isLoading && errors && <p>{errors}</p>}</S.Items>
          </div>
        )}

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isLoading && <Loading />}
        </div>
      </S.Content>
      <DashboardFooter />
      <ToastContainer
        closeButton={false}
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </S.Container>
  );
};

const paths = [
  {
    title: 'Meu Painel',
    link: '/painel',
  },
  {
    title: 'Portal de Boletos',
    link: '',
  },
];
