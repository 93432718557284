import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as S from './styles';
import {
  UserListItem,
  ModalResponsive,
  Loading,
  LoadingCircle,
  Pagination,
  UsersCard,
} from '../../components';
import Edit from '../../assets/buttons/edit.svg';
import Analisys from '../../assets/buttons/analisys.svg';
import Deactivate from '../../assets/buttons/deactivate.svg';
import Reactivate from '../../assets/buttons/reactivate.svg';
import GroupAdd from '../../assets/buttons/group-add.svg';
import { useAccess, useClient } from '../../hooks';
import { searchArrayOfObjects, sendNotification } from '../../utils/functions';
import makeAnimated from 'react-select/animated';
import { usePermissions } from '../../hooks/usePermissions';
import { WithContext as ReactTags } from 'react-tag-input';
import { ClientRegistrationForm } from './client-registration';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FaRegCircleQuestion } from 'react-icons/fa6';

interface UsersProps {
  setTotalEmAnalise: Dispatch<SetStateAction<string | null>>;
}

const toastConfig = {
  autoClose: 3000,
  hideProgressBar: true,
  type: 'success',
};

export const Users: React.FC<UsersProps> = ({ setTotalEmAnalise }) => {
  const {
    returnAllUsers,
    changeUserStatus,
    editUserInfo,
    analyzeUser,
    error,
    isLoading,
    isLoadingAPICall,
    representantes,
    metadata,
  } = useAccess();

  const [
    { profileGroups, areasActing },
    fetchProfilesGroups,
    ,
    ,
    ,
    fetchAreasActing,
  ] = usePermissions();

  const [apiError, setApiError] = useState<string | null>(null);
  const [chosenEconomicGroup, setEconomicGroup] = useState<any>([]);

  const [typeSearch, setTypeSearch] = useState<string>('');
  const [termSearch, setTermSearch] = useState<string>('');

  const [area, setArea] = useState<any>([]);
  const [profile, setProfile] = useState('');
  const [cnpjsRaiz, setCnpjsRaiz] = useState<any>([]);
  const [{ reject }, consultingCnpj] = useClient();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [openReactivateModal, setOpenReactivateModal] = useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  const [user, setUser] = useState<any>(null);
  const [isApprove, setIsApprove] = useState(false);
  const [reproveReason, setReproveReason] = useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    size: 20,
  });

  useEffect(() => {
    fetchProfilesGroups(true);
    fetchAreasActing();
    returnAllUsers({
      type: typeSearch,
      term: termSearch,
      currentPage: pagination.current,
      pageSize: pagination.size,
    });
    setApiError(null);
  }, []);

  const animatedSelectComponents = makeAnimated();
  const totalPages = Math.ceil(
    representantes.pagination?.totalItems / pagination.size,
  );
  const itemsArray = [...Array(totalPages ? totalPages : 0)];

  useEffect(() => {
    if (!openEditModal) {
      setCnpjsRaiz([]);
    } else {
      patchCnpjsRaiz(user);
    }
  }, [openEditModal]);

  const profileTypes = useMemo(() => {
    return profileGroups.map((e: any) => {
      return e;
    });
  }, [profileGroups]);

  useEffect(() => {
    setTotalEmAnalise(metadata.totalEmAnalise);
  }, [setTotalEmAnalise, metadata]);

  const handleAreaChoosen = (area: any, user: any) => {
    setUser({ ...user, areasAtuacao: area });
    setArea(area);
  };

  const paginationSearch = (index: number) => {
    if (index > 0 && index <= totalPages) {
      window.scrollTo(0, 0);
      setPagination({
        current: index,
        size: pagination.size,
      });
      returnAllUsers({
        type: typeSearch,
        term: termSearch,
        currentPage: index,
        pageSize: pagination.size,
      });
    }
  };

  const searchUsers = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    returnAllUsers({
      type: typeSearch,
      term: termSearch,
      currentPage: 1,
      pageSize: pagination.size,
    });
  };

  const handleModalClose = async () => {
    setOpenRegisterModal(false);
    returnAllUsers({
      type: typeSearch,
      term: termSearch,
      currentPage: pagination.current,
      pageSize: pagination.size,
    });
  };

  const handleUserAction = async (
    actionFn: () => Promise<void>,
    successMessage: string,
    errorMessage?: string,
  ) => {
    try {
      await actionFn();
      sendNotification(successMessage, toastConfig);
      setOpenAnalysisModal(false);
      setOpenEditModal(false);
      setReproveReason('');
      paginationSearch(pagination.current);
      setApiError(null);
    } catch (e: any) {
      const errorToDisplay =
        errorMessage || e.response?.data?.errors || e.message;
      setApiError(errorToDisplay);
    }
  };

  const patchCnpjsRaiz = (user: any) => {
    if (!user || !user.cnpj) {
      return;
    }

    const existingCnpjs = new Set(
      cnpjsRaiz.map((cnpj: { id: string; text: string }) => cnpj.id),
    );

    const uniqueFormattedCnpjs = user.cnpj
      .filter((cnpj: any) => !existingCnpjs.has(cnpj))
      .map((cnpj: any) => ({
        id: cnpj,
        text: formatCnpj(cnpj),
      }));

    setCnpjsRaiz((prevCnpjsRaiz: any) => {
      const updatedCnpjs = [...prevCnpjsRaiz, ...uniqueFormattedCnpjs];
      const uniqueCnpjs = Array.from(new Set(updatedCnpjs.map(c => c.id))).map(
        id => updatedCnpjs.find(c => c.id === id),
      );

      return uniqueCnpjs;
    });
  };

  const formatCnpj = (cnpj: string) => {
    if (typeof cnpj !== 'string') {
      return cnpj;
    }
    return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(
      5,
      8,
    )}/${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`;
  };

  const updateUser = async () => {
    const response: any = await editUserInfo({ ...user, areasAtuacao: area });
    if (response?.status >= 200 && response?.status < 300) {
      setOpenEditModal(false);
      sendNotification(
        `As alterações no usuário ${user?.nome} foram realizadas com sucesso.`,
        toastConfig,
      );
      returnAllUsers({
        currentPage: pagination.current,
        pageSize: pagination.size,
      });
    }
  };

  const approveUser = () => {
    handleUserAction(
      () => analyzeUser(user?.id, isApprove, reproveReason),
      `O usuário ${user?.nome} foi aprovado.`,
    );
  };

  const repproveUser = async () => {
    handleUserAction(
      () => analyzeUser(user?.id, isApprove, reproveReason),
      `O usuário ${user?.nome} foi reprovado.`,
    );
  };

  const activateUser = async () => {
    await changeUserStatus(user?.id, true);
    sendNotification(`O usuário ${user?.nome} foi reativado.`, toastConfig);
    setOpenReactivateModal(false);
    paginationSearch(pagination.current);
  };

  const deactivateUser = async () => {
    await changeUserStatus(user?.id, false);
    sendNotification(`O usuário ${user?.nome} foi desativado.`, toastConfig);
    setOpenDeactivateModal(false);
    paginationSearch(pagination.current);
  };

  const renderGrupoEconomico = (grupoEconomico: any) => {
    if (
      !grupoEconomico ||
      !Array.isArray(grupoEconomico) ||
      grupoEconomico.length === 0
    ) {
      return '-';
    }

    const primary =
      typeof grupoEconomico[0] === 'string' ? grupoEconomico[0] : 'Principal';
    const others = Array.isArray(grupoEconomico) ? grupoEconomico.slice(1) : [];

    return (
      <S.ListInline>
        <ul className="no-flex">
          <li>
            <span>Principal</span> {primary}
          </li>
          {others.length > 0 && (
            <li className="same">
              <span>Outros</span> {others.join(', ')}
            </li>
          )}
        </ul>
      </S.ListInline>
    );
  };

  const treatmentUserAttributes = (user: any) => {
    setUser(user);
    setProfile(user.perfil?.nome);
    setEconomicGroup(user.grupoEconomico);
    setArea(null);
    if (user?.areasAtuacao.length > 0) {
      const areasAtuacao: any = user?.areasAtuacao.map((r: any) => {
        const matchingGroup: any = areasActing.find(
          (area: any) => area.label === r,
        );
        const value = matchingGroup ? matchingGroup.value : null;
        return {
          value,
          label: r,
        };
      });
      setArea(areasAtuacao);
    } else {
      setArea([]);
    }
  };

  const handleAdditionCnpjs = async (tag: { text: string; id: string }) => {
    const cnpjText: string = tag.text.replace(/[^\d]/g, '');
    const formattedCnpj = formatCnpj(tag.text);

    const newCnpj = {
      id: tag.id,
      text: formattedCnpj,
    };

    const responseConsulted = await consultingCnpj(cnpjText);
    const grupoEconomico = responseConsulted?.cliente?.grupoEconomico || '';

    if (grupoEconomico && !user?.grupoEconomico.includes(grupoEconomico)) {
      setCnpjsRaiz([...cnpjsRaiz, newCnpj]);
      setUser({
        ...user,
        cnpj: [...user.cnpj, tag.id],
        grupoEconomico: [...user.grupoEconomico, grupoEconomico],
      });
    }
  };

  const handleDeleteCnpjs = (index: number) => {
    const newCnpjs = cnpjsRaiz.filter((_: string, i: number) => i !== index);
    setCnpjsRaiz(newCnpjs);

    const newUserCnpjs = user.cnpj.filter(
      (_: string, i: number) => i !== index,
    );

    const newGrupoEconomico = user.grupoEconomico.filter(
      (_: string, i: number) => i !== index,
    );

    setUser({
      ...user,
      cnpj: newUserCnpjs,
      grupoEconomico: newGrupoEconomico,
    });
  };

  const handleCnpjsDrag = (tag: any, currPos: number, newPos: number) => {
    const newCnpjs = [...cnpjsRaiz];
    const [movedCnpj] = newCnpjs.splice(currPos, 1);
    newCnpjs.splice(newPos, 0, movedCnpj);
    setCnpjsRaiz(newCnpjs);

    const newUserCnpjs = [...user.cnpj];
    const [movedUserCnpj] = newUserCnpjs.splice(currPos, 1);
    newUserCnpjs.splice(newPos, 0, movedUserCnpj);

    const newGrupoEconomico = [...user.grupoEconomico];
    const [movedGrupoEconomico] = newGrupoEconomico.splice(currPos, 1);
    newGrupoEconomico.splice(newPos, 0, movedGrupoEconomico);

    setUser({
      ...user,
      cnpj: newUserCnpjs,
      grupoEconomico: newGrupoEconomico,
    });
  };

  return (
    <S.Container>
      <S.Content>
        <S.SearchWrapper>
          <S.SearchCell>
            <S.Search onSubmit={searchUsers}>
              <S.InputContainer>
                <S.Select
                  value={typeSearch}
                  className="floating"
                  onChange={e => {
                    setTypeSearch(e.target.value);
                    setTermSearch('');
                  }}
                >
                  <S.Option value="">Selecione</S.Option>
                  <S.Option value="cnpj">CNPJ</S.Option>
                  <S.Option value="razao">Nome / Razão Social</S.Option>
                  <S.Option value="email">E-mail</S.Option>
                  <S.Option value="area">Área de atuação</S.Option>
                </S.Select>
                <S.Input
                  maxLength={35}
                  value={termSearch}
                  onChange={e => setTermSearch(e.target.value)}
                  placeholder={'Faça sua busca...'}
                />
              </S.InputContainer>
              <S.ButtonsContainer>
                <S.Button type="submit">Buscar</S.Button>
              </S.ButtonsContainer>
            </S.Search>

            <S.CardText className="normal">
              Confira os dados, altere ou edite perfis de usuários na lista
              abaixo.
            </S.CardText>
          </S.SearchCell>

          <S.CardInfo>
            <S.CardCell className="left">
              <S.CardText className="active">Ativos</S.CardText>
              <p>
                <S.CardText className="bolder">
                  {metadata.totalAtivos ?? '-'}/
                </S.CardText>
                <S.CardText className="smallest">
                  {metadata.total ?? '-'}
                </S.CardText>{' '}
                <S.CardText className="info">usuários</S.CardText>
              </p>
            </S.CardCell>
            <S.CardCell>
              <S.CardText className="inactive">Inativos</S.CardText>
              <p>
                <S.CardText className="bolder">
                  {metadata.totalInativos ?? '-'}/
                </S.CardText>
                <S.CardText className="smallest">
                  {metadata.total ?? '-'}
                </S.CardText>{' '}
                <S.CardText className="info">usuários</S.CardText>
              </p>
            </S.CardCell>
          </S.CardInfo>
          <S.ButtonsContainer className="register">
            <S.IconButton
              className="primary medium"
              onClick={() => {
                setOpenRegisterModal(true);
              }}
            >
              <img src={GroupAdd} width={16} />
              Novo Cliente Final
            </S.IconButton>
          </S.ButtonsContainer>
        </S.SearchWrapper>

        <S.Tabs.TabsContainer>
          {isLoading && <Loading />}
          {representantes.representantes?.map((elm: any) => {
            return (
              <UsersCard key={elm.id} user={elm}>
                <S.CardButtons>
                  <S.IconButton
                    className={!elm.analisado ? 'edit disabled' : 'edit'}
                    onClick={() => {
                      setOpenEditModal(true);
                      setApiError(null);
                      treatmentUserAttributes(elm);
                      patchCnpjsRaiz(elm);
                    }}
                    disabled={!elm.analisado}
                  >
                    <img src={Edit} width={16} />
                    Editar
                  </S.IconButton>
                  {!elm.analisado && (
                    <S.IconButton
                      className="analisys"
                      onClick={() => {
                        setOpenAnalysisModal(true);
                        setIsApprove(true);
                        setApiError(null);
                        treatmentUserAttributes(elm);
                      }}
                    >
                      <img src={Analisys} width={16} />
                      Analisar
                    </S.IconButton>
                  )}
                  {elm.ativo && elm.analisado && (
                    <S.IconButton
                      className="deactivate"
                      onClick={() => {
                        setOpenDeactivateModal(true);
                        setUser(elm);
                      }}
                    >
                      <img src={Deactivate} width={16} />
                      Desativar
                    </S.IconButton>
                  )}
                  {!elm.ativo && elm.analisado && (
                    <S.IconButton
                      className="reactivate"
                      onClick={() => {
                        setOpenReactivateModal(true);
                        setUser(elm);
                      }}
                    >
                      <img src={Reactivate} width={16} />
                      Reativar
                    </S.IconButton>
                  )}
                </S.CardButtons>
              </UsersCard>
            );
          })}
        </S.Tabs.TabsContainer>

        <S.ListContainer>
          {isLoading && <Loading />}
          {representantes.representantes?.map((elm: any) => {
            return (
              <UserListItem key={elm.id} user={elm}>
                <S.IconButton
                  className={!elm.analisado ? 'edit disabled' : 'edit'}
                  onClick={() => {
                    setOpenEditModal(true);
                    setApiError(null);
                    treatmentUserAttributes(elm);
                    patchCnpjsRaiz(elm);
                  }}
                >
                  <img src={Edit} width={16} />
                  Editar
                </S.IconButton>
                {!elm.analisado && (
                  <S.IconButton
                    className="analisys"
                    onClick={() => {
                      setOpenAnalysisModal(true);
                      setApiError(null);
                      treatmentUserAttributes(elm);
                    }}
                  >
                    <img src={Analisys} width={16} />
                    Analisar
                  </S.IconButton>
                )}
                {elm.ativo && elm.analisado && (
                  <S.IconButton
                    className="deactivate"
                    onClick={() => {
                      setOpenDeactivateModal(true);
                      setUser(elm);
                    }}
                  >
                    <img src={Deactivate} width={16} />
                    Desativar
                  </S.IconButton>
                )}
                {!elm.ativo && elm.analisado && (
                  <S.IconButton
                    className="reactivate"
                    onClick={() => {
                      setOpenReactivateModal(true);
                      setUser(elm);
                    }}
                  >
                    <img src={Reactivate} width={16} />
                    Reativar
                  </S.IconButton>
                )}
              </UserListItem>
            );
          })}
        </S.ListContainer>

        {!isLoading && representantes.representantes?.length === 0 && (
          <S.Message>Nenhum resultado encontrado.</S.Message>
        )}

        {!isLoading && representantes.representantes?.length !== 0 && (
          <Pagination
            startWithZero={false}
            currentPage={pagination.current}
            paginationSize={pagination.size}
            paginationSearch={paginationSearch}
            totalPages={totalPages}
          />
        )}
      </S.Content>

      <>
        <ModalResponsive
          title={'Edição de usuário'}
          isOpenModal={openEditModal}
          handleOpenModal={setOpenEditModal}
          isSmall={false}
        >
          <S.Featured
            className="background flexing"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div style={{ flex: 1 }}>
              {[
                { label: 'Nome / Razão Social', value: user?.nome },
                { label: 'E-mail', value: (user?.email ?? '-').toLowerCase() },
                { label: 'Perfil do usuário', value: user?.perfil?.nome },
              ].map((item, index) => (
                <React.Fragment key={index}>
                  {item.value !== undefined && (
                    <>
                      <S.Text className="bold top-margin">{item.label}</S.Text>
                      <S.Text>{item.value ?? '-'}</S.Text>
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>

            <div style={{ flex: 1 }}>
              <S.Text className="bold top-margin">
                Grupo(s) econômico(s){' '}
                <FaRegCircleQuestion
                  data-tooltip-id="info-tooltip"
                  data-tooltip-content="O grupo econômico principal é determinado a partir do primeiro CNPJ raiz informado."
                  style={{
                    width: '1.1em',
                    height: '1.1em',
                    marginLeft: '5px',
                    color: 'gray',
                    cursor: 'pointer',
                  }}
                />
              </S.Text>
              <ReactTooltip id="info-tooltip" place="top" />
              {renderGrupoEconomico(user?.grupoEconomico)}{' '}
            </div>
          </S.Featured>

          <S.Featured>
            {user?.perfil?.nome !== 'cliente-final' && (
              <>
                <S.Text className="bold">Perfil do usuário</S.Text>
                <S.Select
                  value={profile}
                  onChange={e => {
                    setProfile(e.target.value);
                    setUser({
                      ...user,
                      perfil: searchArrayOfObjects(
                        profileTypes,
                        'nome',
                        e.target.value,
                      ),
                    });
                  }}
                >
                  {profileTypes.map((e: any) => {
                    return (
                      <S.Option key={e.id} value={e.nome}>
                        {e.nome}
                      </S.Option>
                    );
                  })}
                </S.Select>
              </>
            )}
            <S.FormControl>
              <S.Text className="bold top-margin">CNPJs Raiz</S.Text>
              <ReactTags
                classNames={{ tags: 'ReactTags__tags secondary' }}
                tags={cnpjsRaiz}
                handleAddition={handleAdditionCnpjs}
                handleDelete={handleDeleteCnpjs}
                handleDrag={handleCnpjsDrag}
                delimiters={[188, 13]}
                placeholder="Digite aqui os CNPJs..."
              />
              {reject && <S.Text className="small-danger">{reject}</S.Text>}
            </S.FormControl>
            <S.FormControl>
              <S.Text className="bold">Área(s) de atuação</S.Text>
              <S.CustomMultiSelect
                classNamePrefix={'Select'}
                className="secondary"
                components={animatedSelectComponents}
                isMulti
                options={areasActing}
                value={area}
                onChange={opts => handleAreaChoosen(opts, user)}
                placeholder="Selecione"
                isClearable
              />
            </S.FormControl>
          </S.Featured>
          <S.Buttons>
            <S.IconButton
              className="text"
              onClick={() => setOpenEditModal(false)}
            >
              Cancelar
            </S.IconButton>
            <S.IconButton
              className="update"
              onClick={updateUser}
              disabled={isLoadingAPICall}
            >
              {isLoadingAPICall ? <LoadingCircle /> : 'Editar'}
            </S.IconButton>
          </S.Buttons>
        </ModalResponsive>
        <ModalResponsive
          title={'Análise de novo acesso'}
          isOpenModal={openAnalysisModal}
          handleOpenModal={setOpenAnalysisModal}
        >
          <S.Text>
            Você está analisando a solicitação de acesso do usuário{' '}
            <S.Text className="bold inline">{user?.nome}</S.Text>. Essa ação
            concederá ou rejeitará o acesso ao sistema. Você confirma?
          </S.Text>
          <S.RadioButtonWrapper>
            <label
              className="form-control"
              onClick={() => {
                setIsApprove(true);
              }}
            >
              <input type="radio" name="radio" checked={isApprove} />
              <span className={isApprove ? 'bolder' : ''}>
                Sim, liberar acesso ao usuário.
              </span>
            </label>

            <label
              className="form-control"
              onClick={() => {
                setIsApprove(false);
              }}
            >
              <input type="radio" name="radio" checked={!isApprove} />
              <span className={!isApprove ? 'bolder' : ''}>
                Não, negar acesso ao usuário.
              </span>
            </label>
          </S.RadioButtonWrapper>
          {!isApprove && (
            <S.InputReason
              value={reproveReason}
              onChange={e => setReproveReason(e.target.value)}
              placeholder="Informe ao usuário a razão da reprovação da sua solicitação de acesso."
            ></S.InputReason>
          )}
          <S.Buttons className="top-margin">
            <S.IconButton
              className="text"
              onClick={() => setOpenAnalysisModal(false)}
            >
              Cancelar
            </S.IconButton>
            {isApprove && (
              <S.IconButton
                className="success"
                onClick={approveUser}
                disabled={isLoadingAPICall}
              >
                {isLoadingAPICall ? <LoadingCircle /> : 'Aprovar'}
              </S.IconButton>
            )}
            {!isApprove && (
              <S.IconButton
                className={!reproveReason ? 'disabled danger' : 'danger'}
                onClick={repproveUser}
                disabled={isLoadingAPICall || !reproveReason}
              >
                {isLoadingAPICall ? <LoadingCircle /> : 'Reprovar'}
              </S.IconButton>
            )}
          </S.Buttons>
        </ModalResponsive>
        <ModalResponsive
          title={'Desativação de usuário'}
          isOpenModal={openDeactivateModal}
          handleOpenModal={setOpenDeactivateModal}
        >
          <S.Text>
            Você está desativando o usuário{' '}
            <S.Text className="bold inline">{user?.nome}</S.Text>. Essa ação
            bloqueará o acesso ao sistema. Deseja continuar?
          </S.Text>
          <S.Buttons>
            <S.IconButton
              className="text"
              onClick={() => setOpenDeactivateModal(false)}
            >
              Cancelar
            </S.IconButton>
            <S.IconButton
              className="danger"
              onClick={deactivateUser}
              disabled={isLoadingAPICall}
            >
              {isLoadingAPICall ? <LoadingCircle /> : 'Desativar'}
            </S.IconButton>
          </S.Buttons>
        </ModalResponsive>
        <ModalResponsive
          title={'Reativação de usuário'}
          isOpenModal={openReactivateModal}
          handleOpenModal={setOpenReactivateModal}
        >
          <S.Text>
            Você está reativando o usuário{' '}
            <S.Text className="bold inline">{user?.nome}</S.Text>. Essa ação
            concederá o acesso ao sistema. Deseja continuar?
          </S.Text>
          <S.Buttons>
            <S.IconButton
              className="text"
              onClick={() => setOpenReactivateModal(false)}
            >
              Cancelar
            </S.IconButton>
            <S.IconButton
              className="success"
              onClick={activateUser}
              disabled={isLoadingAPICall}
            >
              {isLoadingAPICall ? <LoadingCircle /> : 'Ativar'}
            </S.IconButton>
          </S.Buttons>
        </ModalResponsive>

        <ModalResponsive
          title={'Cadastro de cliente final'}
          isOpenModal={openRegisterModal}
          handleOpenModal={setOpenRegisterModal}
        >
          <ClientRegistrationForm onCancel={handleModalClose} />
        </ModalResponsive>
      </>

      <ToastContainer
        closeButton={false}
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </S.Container>
  );
};
