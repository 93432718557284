import React, { useState } from 'react';
import * as S from './styles';
import {
  compareDateWithToday,
  formatCoin,
  limitString,
} from '../../utils/functions';

import { WithContext as ReactTags } from 'react-tag-input';

import { IBoleto } from '../../interfaces';
import { useBoletos, useStorage } from '../../hooks';
import Download from '../../assets/download.png';
import DocumentViewer from '../document-viewer';
import { Loading } from '../loading';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const OrderCardBoleto: React.FC<IBoleto> = ({
  boleto,
  isDefault = false,
}) => {
  const {
    emailSendStatus,
    emailGroupSendStatus,
    isSendingEmail,
    base64,
    isLoadingBase64,
    errorsBase64,
    loadBase64,
    downloadBillet,
    sendBilletByEmail,
  } = useBoletos();
  const [isActive, setIsActive] = useState(false);
  const [isActiveGroup, setIsActiveGroup] = useState(false);

  // Local Storage
  const { getItemOnStorage } = useStorage();
  const currentUser = getItemOnStorage('currentUser');
  const user = JSON.parse(currentUser || '');

  const vendorEmail = user.userEmail;

  const clientEmail =
    boleto?.sClienteEmailFinanceiro || 'E-mail do cliente ausente.';

  // TODO: Implementar visualizacao de multiplos boletos.
  // const tagEmails = boleto?.sClienteEmailFinanceiro?.split(';');
  // const clientTagEmails =
  //   tagEmails?.map(email => {
  //     return {
  //       id: email,
  //       text: email,
  //     };
  //   }) ?? [];

  const [tags, setTags] = useState<any>([
    {
      id: vendorEmail,
      text: vendorEmail,
    },
    {
      id: clientEmail,
      text: clientEmail,
    },
  ]);

  const [groupTags, setGroupTags] = useState<any>([
    {
      id: vendorEmail,
      text: vendorEmail,
    },
  ]);

  const handleAddition = (tag: any) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag: any, currPos: any, newPos: any) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleDelete = (i: any) => {
    setTags(tags.filter((tag: any, index: any) => index !== i));
  };

  const numberCPF_CNPJ = boleto.sClienteCNPJ_CPF ?? '';

  const maskedCPF_CNPJ =
    numberCPF_CNPJ.length !== 11
      ? numberCPF_CNPJ.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5',
        )
      : numberCPF_CNPJ.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

  const openGroupItem = () => {
    setIsActiveGroup(!isActiveGroup);
    setIsActive(false);
  };

  const openSingleItem = () => {
    setIsActive(!isActive);
    setIsActiveGroup(false);
    onClickBase64(isActive);
  };

  const onClickBase64 = (isActive: boolean) => {
    if (!isActive) {
      loadBase64({
        dateStart: '01/01/2023',
        dateEnd: '31/12/2024',
        tituloNumero: boleto.sTituloNumero,
        numeroParcela: boleto.sTituloSeqVencto,
      });
    }
  };

  const downloadBase64 = (base64: string) => {
    if (!base64 || base64.length === 0) return;

    downloadBillet(base64 ?? '', 'boleto.pdf');
  };

  return (
    <>
      {isDefault ? (
        <S.TabsRow className={`show ${isActiveGroup ? 'active' : ''}`}>
          <S.TabsData className="start" onClick={openGroupItem}>
            {limitString(boleto.clienteNome ?? '')}
          </S.TabsData>
          <S.TabsData onClick={openGroupItem}>
            {formatCoin(
              parseFloat(boleto.somaValorAVencer ?? '0')
                .toFixed(2)
                .replace('.', ','),
            )}
          </S.TabsData>
          <S.TabsData onClick={openGroupItem}>
            {formatCoin(
              parseFloat(boleto.somaValorVencido ?? '0')
                .toFixed(2)
                .replace('.', ','),
            )}
          </S.TabsData>
          <S.TabsData onClick={openGroupItem}>
            {formatCoin(
              parseFloat(boleto.somaValorOriginal ?? '0')
                .toFixed(2)
                .replace('.', ','),
            )}
          </S.TabsData>
          <S.TabsData onClick={openGroupItem}>{maskedCPF_CNPJ}</S.TabsData>
        </S.TabsRow>
      ) : (
        <S.TabsRow
          className={`show ${isActive ? 'active' : ''}`}
          onClick={() => onClickBase64(isActive)}
        >
          <S.TabsData className="start" onClick={openSingleItem}>
            {limitString(boleto.sClienteNome ?? '')}
          </S.TabsData>
          <S.TabsData onClick={openSingleItem}>
            {boleto?.sTituloDataVencimentoAtual?.slice(0, 10) ?? ''}
          </S.TabsData>
          <S.TabsData onClick={openSingleItem}>
            {boleto.sTituloNumero ?? ''}
          </S.TabsData>
          <S.TabsData onClick={openSingleItem}>
            {boleto.sTituloSeqVencto ?? '0'}
          </S.TabsData>
          <S.TabsData onClick={openSingleItem}>
            {formatCoin(boleto.sTituloValorOriginal ?? '')}
          </S.TabsData>
          <S.TabsData onClick={openSingleItem}>{maskedCPF_CNPJ}</S.TabsData>
          <S.TabsData>
            <S.TabDownload
              onClick={() => downloadBase64(base64)}
              src={Download}
            />
          </S.TabsData>
        </S.TabsRow>
      )}

      <S.TabsRowForm className={isActive ? 'show active' : ''}>
        <S.TabsDataForm colSpan={isDefault ? 3 : 5}>
          <S.FileView>
            {!isLoadingBase64 && base64 && (
              <DocumentViewer base64Data={base64} />
            )}
            {
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {isLoadingBase64 && <Loading />}
              </div>
            }
            {!isLoadingBase64 && !base64 && (
              <S.MissingDoc>
                O título selecionado ainda não está disponível em nosso sistema!
                Por favor, tente novamente mais tarde.
              </S.MissingDoc>
            )}
          </S.FileView>
        </S.TabsDataForm>
        <S.TabsDataForm colSpan={2} className="limited-area">
          <S.TitleSend>E-mails para envio:</S.TitleSend>
          <S.SubitleSend>
            (por motivos de segurança, use o domínio da empresa)
          </S.SubitleSend>
          <ReactTags
            tags={tags}
            inline={false}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            delimiters={delimiters}
            placeholder="Digite aqui os emails..."
            autofocus={false}
            inputFieldPosition="inline"
            autocomplete
            readOnly
          />
          <S.ButtonSend
            onClick={() =>
              sendBilletByEmail(
                tags.map((elm: any) => elm.text),
                boleto,
              )
            }
            disabled={isSendingEmail || !boleto.sClienteEmailFinanceiro}
            className={
              tags.length === 0 || !boleto.sClienteEmailFinanceiro
                ? 'disabled'
                : ''
            }
            type="submit"
          >
            {isSendingEmail ? 'Enviando...' : 'Enviar 2ª Via'}
          </S.ButtonSend>
          {emailSendStatus === 'error' && (
            <S.ErrorMessage>Erro ao enviar</S.ErrorMessage>
          )}
          {emailSendStatus === 'success' && (
            <S.SuccessMessage>E-mail enviado</S.SuccessMessage>
          )}
        </S.TabsDataForm>
      </S.TabsRowForm>

      {boleto.listagemBoletosCliente && (
        <S.TabsRowForm className={isActiveGroup ? 'show active' : ''}>
          {boleto.listagemBoletosCliente && (
            <S.TabsDataForm colSpan={isDefault ? 3 : 5}>
              <S.GroupView>
                <S.GroupItem>
                  <div>Vencimento</div>
                </S.GroupItem>
                <S.GroupItem>
                  <div>Notal Fiscal</div>
                </S.GroupItem>
                <S.GroupItem>
                  <div>Parcela</div>
                </S.GroupItem>
                <S.GroupItem>
                  <div>Valor</div>
                </S.GroupItem>
              </S.GroupView>
              {boleto.listagemBoletosCliente.map((elm: any) => {
                return (
                  <>
                    <S.GroupView>
                      <S.GroupItem>
                        <div
                          className={
                            compareDateWithToday(
                              elm?.sTituloDataVencimentoAtual?.slice(0, 10),
                            ) === 'menor'
                              ? 'red'
                              : ''
                          }
                        >
                          {elm?.sTituloDataVencimentoAtual?.slice(0, 10) ?? ''}
                        </div>
                      </S.GroupItem>
                      <S.GroupItem>
                        <div
                          className={
                            compareDateWithToday(
                              elm?.sTituloDataVencimentoAtual?.slice(0, 10),
                            ) === 'menor'
                              ? 'red'
                              : ''
                          }
                        >
                          {elm?.sTituloNumero ?? ''}
                        </div>
                      </S.GroupItem>
                      <S.GroupItem>
                        <div
                          className={
                            compareDateWithToday(
                              elm?.sTituloDataVencimentoAtual?.slice(0, 10),
                            ) === 'menor'
                              ? 'red'
                              : ''
                          }
                        >
                          {elm?.sTituloSeqVencto ?? '0'}
                        </div>
                      </S.GroupItem>
                      <S.GroupItem>
                        <div
                          className={
                            compareDateWithToday(
                              elm?.sTituloDataVencimentoAtual?.slice(0, 10),
                            ) === 'menor'
                              ? 'red'
                              : ''
                          }
                        >
                          {formatCoin(elm?.sTituloValorOriginal ?? '')}
                        </div>
                      </S.GroupItem>
                    </S.GroupView>
                  </>
                );
              })}
            </S.TabsDataForm>
          )}
          <S.TabsDataForm colSpan={2} className="limited-area">
            <S.TitleSend>E-mail para envio:</S.TitleSend>
            <S.SubitleSend>
              (por motivos de segurança, use o domínio da empresa)
            </S.SubitleSend>
            <ReactTags
              tags={[
                ...groupTags,
                {
                  id: boleto.listagemBoletosCliente[0].sClienteEmailFinanceiro,
                  text:
                    boleto.listagemBoletosCliente[0].sClienteEmailFinanceiro.split(
                      ';',
                    )[0] || 'E-mail ausente.',
                },
              ]}
              inline={false}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              delimiters={delimiters}
              placeholder="Digite aqui os e-mails..."
              autofocus={false}
              inputFieldPosition="inline"
              autocomplete
              readOnly
            />
            <S.ButtonSend
              onClick={() => sendBilletByEmail(tags[0].text, boleto)}
              disabled={
                isSendingEmail ||
                !boleto.listagemBoletosCliente[0].sClienteEmailFinanceiro
              }
              className={
                tags.length === 0 ||
                isSendingEmail ||
                !boleto.listagemBoletosCliente[0].sClienteEmailFinanceiro
                  ? 'disabled'
                  : ''
              }
              type="submit"
            >
              {isSendingEmail ? 'Enviando...' : 'Enviar 2ª Via'}
            </S.ButtonSend>
            {emailGroupSendStatus && (
              <S.Message>{emailGroupSendStatus}</S.Message>
            )}
          </S.TabsDataForm>
        </S.TabsRowForm>
      )}
    </>
  );
};
