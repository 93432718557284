import { useState } from 'react';
import { dealerProvider } from '../../services/api';
import { generateToken } from '../../services/token';
import { IRegisterFinalClient } from '../../interfaces';
import { sendNotification } from '../../utils/functions';

export const useAccess = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAPICall, setIsLoadingAPICall] = useState(false);
  const [representantes, setRepresentantes] = useState<any>([]);
  const [metadata, setMetadata] = useState<any>([]);
  const [error, setError] = useState<any>(null);

  const handleAPIError = (e: any) => {
    setIsLoadingAPICall(false);
    setError(
      e.response.data.errors ||
        e.response ||
        e.message ||
        'Erro desconhecido na API',
    );
  };

  const performAPIRequest = async (
    url: string,
    data: any,
    successMessage?: string,
  ) => {
    try {
      setIsLoadingAPICall(true);
      const accessToken = await generateToken();
      const response = await dealerProvider.post(url, data, {
        headers: {
          Accept: '*/*',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setIsLoadingAPICall(false);

      return response;
    } catch (e: any) {
      setIsLoadingAPICall(false);
      handleAPIError(e);
      sendNotification(`Error - ${e.response.data.errors || 'desconhecido'}`, {
        type: 'error',
      });
    }
  };

  const returnAllUsers = async (searchParams: any) => {
    const { type, term, currentPage, pageSize } = searchParams;
    setRepresentantes([]);
    setMetadata([]);
    setIsLoading(true);
    try {
      const accessToken = await generateToken();
      const response = await dealerProvider.get(
        `api/listar?search=${type}&text=${term}&page=${currentPage}&pagesize=${pageSize}`,
        {
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      setMetadata(response.data.data);
      setRepresentantes(response.data.data);
    } catch (e: any) {
      handleAPIError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const changeUserStatus = async (id: string, activate: boolean) => {
    await performAPIRequest('api/trocastatus', { id, ativo: activate });
  };

  const editUserInfo = async (user: any) => {
    const { id, areasAtuacao, grupoEconomico, perfil } = user || {};

    const requestData = {
      id,
      areasAtuacao,
      grupoEconomico,
      perfil: {
        id: perfil.id,
        nome: perfil.nome,
      },
    };

    return performAPIRequest('api/editar', requestData);
  };

  const analyzeUser = async (
    id: string,
    analyzed: boolean,
    text: string,
    user?: any,
  ) => {
    const requestData: any = {
      id,
      analisado: analyzed,
      justificativa: text,
      gruposEconomicos: user?.gruposEconomicos,
    };

    await performAPIRequest('api/analise', requestData);
  };

  const registerUser = async (user: any) => {
    try {
      setIsLoading(true);
      setIsLoadingAPICall(true);
      setError(null);
      const accessToken = '';
      const newUser = await dealerProvider.post(
        'api/cadastrar-para-analise',
        {
          nome: user.nome,
          cnpj: user.cnpj,
          email: user.email,
          areaAtuacao: user.areaAtuacao || '',
          perfil: {},
        },
        {
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      setIsLoading(false);
      return newUser;
    } catch (e: any) {
      handleAPIError(e);
      return e;
    } finally {
      setIsLoadingAPICall(false);
    }
  };

  const registerFinalClient = async (
    data: IRegisterFinalClient,
  ): Promise<boolean> => {
    try {
      const { cnpjRaiz, grupoEconomico, emails, areasAtuacao } = data;

      setIsLoadingAPICall(true);
      setError(null);
      const accessToken = await generateToken();

      await dealerProvider.post(
        'api/FilaRepresentanteCadastro/enviar',
        { cnpjRaiz, grupoEconomico, emails, areasAtuacao },
        { headers: { Accept: '*/*', Authorization: `Bearer ${accessToken}` } },
      );

      setIsLoadingAPICall(false);
      return true;
    } catch (e: any) {
      handleAPIError(e);
      setIsLoadingAPICall(false);
      return false;
    }
  };

  return {
    representantes,
    metadata,
    isLoading,
    isLoadingAPICall,
    error,
    returnAllUsers,
    changeUserStatus,
    editUserInfo,
    analyzeUser,
    registerUser,
    registerFinalClient,
    handleAPIError,
  };
};
